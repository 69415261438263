//产品类型协议档案

let protRecordDevList = [
    {
        name: 'Trace2606BPL',
        comp: [
            {
                adsname: 'pv_state_ads',
                addr: '3201H',
                value: 0
            },
            {
                adsname: 'pv_voltage_ads',
                addr: '3100H',
                value: 0
            },
            {
                adsname: 'pv_current_ads',
                addr: '3101H',
                value: 0
            },
            {
                adsname: 'pv_power_ads',
                addr: '3103H',
                value: 0
            },
            {
                adsname: 'pv_power_generation',
                addr: '330DH',
                value: 0
            },


            {
                adsname: 'load_state_ads',
                addr: '3202H',
                value: 0
            },
            {
                adsname: 'load_voltage_ads',
                addr: '310CH',
                value: 0
            },
            {
                adsname: 'load_current_ads',
                addr: '310DH',
                value: 0
            },
            {
                adsname: 'load_power_ads',
                addr: '310FH',
                value: 0
            },
            {
                adsname: 'load_energy_used',
                addr: '3305H',
                value: 0
            },



            {
                adsname: 'battery_state_ads',
                addr: '3200H',
                value: 0
            },
            {
                adsname: 'battery_electricity_ads',
                addr: '311AH',
                value: 0
            },
            {
                adsname: 'battery_temp',
                addr: '3111H',
                value: 0
            },
            {
                adsname: 'battery_voltage_ads',
                addr: '331AH',
                value: 0
            },
            {
                adsname: 'battery_current_ads',
                addr: '331BH',
                value: 0
            }

        ]
    },
]


const protRecordAddress = {
    getDevRealTimeInfo(devTypeStr, dateObj) {
        let protRecordDevComp = []
        for (let i in protRecordDevList) {
            if (protRecordDevList[i].name === devTypeStr) {
                protRecordDevComp = protRecordDevList[i].comp
                break
            }
        }
        if (protRecordDevComp === []) {
            return protRecordDevComp;
        }
        for (let j in protRecordDevComp) {
            for (let k in dateObj) {
                if (dateObj[k].modbusaddr === protRecordDevComp[j].addr) {
                    console.log(dateObj[k].dec)
                    protRecordDevComp[j].value = dateObj[k].dec
                }
            }
        }
        return protRecordDevComp

    }
}

export default protRecordAddress
