<template>
  <!-- 顶部 -->
  <div id="c-header">
    <div class="container">
      <!-- 顶部logo -->
      <a class="c-logo" href="" title="VEICHI数能">
        <div class="c-img-box">
          <img src="../../assets/logo.png" alt="VEICHI">
          <img src="../../assets/logo.png" alt="VEICHI">
        </div>
      </a>
      <!-- 右侧 -->
      <div class="c-right-box">

        <!-- 顶部pc导航 -->
        <ul class="c-nav font18">
          <li class="on" style=" cursor:pointer"><span @click="changeMenu('/','')">{{ $t("home") }}</span></li>
          <li class="li-p-sub">
            <a @click="changeMenu('/about_us','')"> {{ $t("about_us") }}</a>
            <div class="menuCont">
              <el-row class="us-row">
                <el-col :span="8" class="us-col">
                  <div class="us-col-d1" @click="changeMenu('/about_us',1)">
                    <el-button class="us-col-but" type="info" icon="el-icon-my-choose" circle></el-button>
                  </div>
                  <!--                  公司简介-->
                  <div class="us-col-d2">{{ $t("company_profile") }}</div>
                </el-col>


                <el-col :span="8" class="us-col">
                  <div class="us-col-d1" @click="changeMenu('/about_us',2)">
                    <el-button class="us-col-but" type="info" icon="el-icon-my-fz" circle></el-button>
                  </div>
                  <!--                  发展历程-->
                  <div class="us-col-d2">{{ $t("development_path") }}</div>
                </el-col>
                <!--                <el-col :span="6" class="us-col">-->
                <!--                  <div class="us-col-d1">-->
                <!--                    <el-button class="us-col-but" type="info" icon="el-icon-my-ryzz" circle></el-button>-->
                <!--                  </div>-->
                <!--                  <div class="us-col-d2">资质荣誉</div>-->
                <!--                </el-col>-->
                <el-col :span="8" class="us-col">
                  <div class="us-col-d1" @click="changeMenu('/about_us',3)">
                    <el-button class="us-col-but" type="info" icon="el-icon-my-zp" circle></el-button>
                  </div>
                  <!--                  人才招聘-->
                  <div class="us-col-d2">{{ $t("job") }}</div>
                </el-col>
              </el-row>
            </div>
          </li>
          <li class="li-p-sub">
            <a class="li-a"> {{ $t("products_and_solutions") }}</a>

            <el-row class="menuCont">
              <el-col :span="12">
                <div class="menu_data" style=" min-width: 50%; height: 100%; ">
                  <!--产品-->
                  <div style="line-height: 0.45rem; padding-top: 0.2rem; color: #4ba477; font-size: 0.24rem">
                    {{ $t("product") }}
                  </div>
                  <el-row>
                    <el-col :span="8">
                      <img style="width: 100%" src="../../assets/header_jpg/test.jpg">
                    </el-col>
                    <el-col :span="16" style="height: 100%">

                      <el-row style="padding: 0.06rem 0 0 0.3rem;  display: flex;">

                        <div v-for="(item,index) in product_list">
                          <h4 class="pc_nav_body" @click="changeMenu('/product',index+1)">{{ item.industryType.name }}</h4>
                          <h4 class="pc_nav_item"
                              v-for="(por,ind) in item.productType"
                              @click="openProTypeList('/pro_type_list',ind+1,item.productType)">{{ por.name }}</h4>
                        </div>

                        <!--                        <div>-->
                        <!--                          <h4 style="padding-bottom: 0.22rem; color: #000; font-weight: 700"  @click="changeMenu('/product','')">电池测试产品</h4>-->
                        <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">电芯充放电测试系统</h4>-->
                        <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">模组充放电测试系统</h4>-->
                        <!--                        </div>-->
                        <!--                        <div style="padding-left: 0.3rem">-->
                        <!--                          <h4 style="padding-bottom: 0.22rem; color: #000; font-weight: 700">储能产品</h4>-->
                        <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">储能逆变器</h4>-->
                        <!--                        </div>-->

                        <!--                        <div style="padding-left: 0.3rem">-->
                        <!--                          <h4 style="padding-bottom: 0.22rem; color: #000; font-weight: 700">定制产品</h4>-->
                        <!--                        </div>-->
                      </el-row>

                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="1">
                <!--                <div class="menu_data"-->
                <!--                     style="height: 2rem; margin-left: 0.3rem; width: 1px; margin-top: 0.29rem; background-color: #4ba477 "></div>-->
              </el-col>
              <el-col :span="11">
                <!--                <div class="menu_data" style=" min-width: 50%; height: 100%;">-->
                <!--                  <div style="line-height: 0.45rem; padding-top: 0.2rem; color: #4ba477; font-size: 0.24rem">方案</div>-->
                <!--                  <el-row>-->
                <!--                    <el-col :span="8">-->
                <!--                      <img style="width: 100%" src="../../assets/header_jpg/test.jpg">-->
                <!--                    </el-col>-->
                <!--                    <el-col :span="16" style="height: 100%">-->
                <!--                      <el-row style="padding: 0.06rem 0 0 0.3rem;  display: flex; align-items: center ">-->
                <!--                        <div>-->
                <!--                          <h4 style="padding-bottom: 0.22rem; color: #000; font-weight: 700">充电方案</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">模块产品</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">Energy storacts</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">模块产品</h4>-->
                <!--                        </div>-->
                <!--                        <div style="padding-left: 0.3rem">-->
                <!--                          <h4 style="padding-bottom: 0.22rem; color: #000; font-weight: 700">储能方案</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">Energy storage-->
                <!--                            products</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">模块产品2</h4>-->
                <!--                          <h4 style="padding-bottom: 0.19rem; color: #000;font-size: 0.17rem  ">模块产品3</h4>-->
                <!--                        </div>-->
                <!--                      </el-row>-->
                <!--                    </el-col>-->
                <!--                  </el-row>-->
                <!--                </div>-->
              </el-col>
            </el-row>

          </li>
          <li class="li-p-sub">
            <a @click="changeMenu('/news',1)"> {{ $t("news") }}</a>
            <div class="menuCont">
              <el-row class="menuCont">
                <el-col :span="11">
                  <div class="menu_data" style=" min-width: 50%; height: 100%; ">
                    <div style="line-height: 0.45rem; padding-top: 0.2rem; color: #4ba477; font-size: 0.24rem">
                      {{ $t("new") }}
                    </div>
                    <el-row>
                      <el-col :span="8">
                        <img style="width: 100%" src="../../assets/header_jpg/xwzx.jpg">
                      </el-col>
                      <el-col :span="16" style="height: 100%">

                        <el-row style="padding: 0.06rem 0 0 0.3rem;  display: flex; align-items: center ">
                          <div>
                            <!--公司新闻-->
                            <h4 class="pc_nav_body"
                                @click="changeMenu('/news',1)">{{ $t("company_news") }}</h4>
                          </div>
                          <div style="padding-left: 0.3rem">
                            <!--行业动态-->
                            <h4 class="pc_nav_body"
                                @click="changeMenu('/news',2)">{{ $t("industry_news") }}</h4>
                          </div>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </li>
          <li class="on" style=" cursor:pointer"><span @click="changeMenu('/contact_us','')">{{
              $t("contact_us")
            }}</span></li>
        </ul>

        <!--移动端顶部导航-->
        <el-row :gutter="24" class="nav_mob">
          <el-col :span="24">
            <el-collapse-transition>
              <div v-show="show3">
                <li class="nav_mob_item" @click="changeMenuMob('/','')"><a >{{ $t("home") }}</a></li>
                <li class="nav_mob_item" @click="changeMenuMob('/about_us','')"><a >{{ $t("about_us") }}</a></li>
                <li class="nav_mob_item" @click="changeMenuMob('/product','')"><a>{{ $t("product") }}</a></li>
                <li class="nav_mob_item" @click="changeMenuMob('/news','')"><a>{{ $t("news") }}</a></li>
                <li class="nav_mob_item" @click="changeMenuMob('/contact_us','')"><a>{{ $t("contact_us") }}</a></li>

              </div>
            </el-collapse-transition>
          </el-col>
        </el-row>

        <!--移动端菜单按钮-->
        <div class="c-switch banner_menu" id="openMenu" @click="setMenuHover">
          <s></s>
          <s></s>
          <s></s>
        </div>
      </div>

      <!-- 顶部功能区 -->
      <div class="c-gn font18">
        <img style="height: 16px;" src="../../assets/png/dq.png">
        <span style="min-width: 60px;">
          <el-dropdown trigger="click" @command="changeType">
            <span class="el-dropdown-link" style="color: #ffffff">
              {{ $t("language") }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu class="c-gn-menu-top">
              <el-dropdown-item command="zh">CN</el-dropdown-item>
              <el-dropdown-item command="en">EN</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </span>
        <img @click="changeMenu('/search','')" class="c-gn-search" style="height: 17px;"
             src="../../assets/png/search.png">
      </div>
    </div>

  </div>
</template>

<script>

import $ from 'jquery'

export default {
  name: "Header",
  components: {},
  data() {
    return {
      show3: false,
      product_list: [],
    }
  },
  mounted() {

  },

  created() {
    this.getProductList()
  },
  methods: {
    //移动端点击菜单效果
    setMenuHover() {
      //获取所有的li标签
      $('#openMenu').toggleClass('changeFigure');
      event.stopPropagation()
      this.show3 = !this.show3
    },

    changeMenu(path, e) {
      this.$router.push({path: path, query: {id: e}})
    },

    changeMenuMob(path, e) {
      this.$router.push({path: path, query: {id: e}})
      // this.show3 = !this.show3
      this.setMenuHover()
    },

    //跳转到产品列表页
    openProTypeList(path, index, obj) {
      // alert(path+'-----'+index+'-----'+obj)
      this.$router.push({path: path, query: {id: index, productsList: obj}})
    },

    //切换国际化类型
    changeType(type) {
      //此处做了语言选择记录
      localStorage.setItem('locale', type);
      //修改显示语言
      this.$i18n.locale = type;
      this.changeMenu('/', null)
      location.reload();
    },

    //获取产品一级分类和二级分类
    getProductList() {

      this.$api.vcApiGet('product/productList').then(res => {
        let locale = localStorage.getItem('locale')
        if (res.data.result.length > 0) {
          let list = res.data.result
          console.log("DDDDDDDDDDD---------------------------------", list)

          for (let i in list) {
            if (locale === 'en') {
              list[i].industryType.name = list[i].industryType.nameEn
              if (list[i].productType.length > 0) {
                let productTypeLst = list[i].productType
                for (let j in productTypeLst) {
                  productTypeLst[j].name = productTypeLst[j].nameEn
                  productTypeLst[j].subtitle = productTypeLst[j].subtitleEn
                  productTypeLst[j].title = productTypeLst[j].titleEn
                }
                list[i].productType = productTypeLst
              }
            } else {
              list[i].industryType.name = list[i].industryType.nameCn
              if (list[i].productType.length > 0) {
                let productTypeLst = list[i].productType
                for (let j in productTypeLst) {
                  productTypeLst[j].name = productTypeLst[j].nameCn
                  productTypeLst[j].subtitle = productTypeLst[j].subtitleCn
                  productTypeLst[j].title = productTypeLst[j].titleCn
                }
                list[i].productType = productTypeLst
              }
            }
          }

          this.product_list = list
        }
      })
    }
  }
}
</script>

<style lang="less">
@import './css/style.css';
@import './css/header_icon.css';

@media (max-width: 2560px) {
  .c-gn-search {
    margin-left: 20px;
  }

  .c-gn-menu-top {
    margin-top: -20px;
  }

}

@media (max-width: 1580px) {
  .c-gn-search {
    margin-left: 40px;
  }

  .c-gn-menu-top {
    margin-top: -20px;
  }
}

@media (max-width: 1260px) {
  .c-gn-search {
    margin-left: 40px;
  }

  .c-gn-menu-top {
    margin-top: -10px;
  }
}

@media (max-width: 767px) {
  .c-gn-search {
    margin-left: 0px;
  }

  .c-gn-menu-top {
    margin-top: -4px;
  }
}

.pc_nav_body{
  padding: 0 0.22rem 0.2rem 0.1rem; color: #000; font-weight: 700
}

.pc_nav_body:hover{
  color: #4ba477;
}

.pc_nav_item{
  padding: 0 0.19rem 0.2rem 0.1rem; color: #000;font-size: 0.17rem
}
.pc_nav_item:hover{
  color: #4ba477;
}

/* banner三条横线——菜单 start */
.banner_menu {
  height: 12px;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, .04);
  cursor: pointer;

  s {
    background-color: #fff;
    position: absolute;
    width: 22px;
    height: 1.5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    pointer-events: auto;

    &:first-child {
      top: 3px;
    }

    &:nth-child(2) {
      top: 9px;
    }

    &:nth-child(3) {
      top: 15px;
    }
  }
}

/* banner三条横线——菜单 end */

/* banner三条横线——菜单 变成叉号 start */
.changeFigure s:first-child {
  transform: rotate(45deg) translate(1px) !important;
  top: -1px;
  background-color: #fff;
  opacity: 1;
}

.changeFigure s:nth-child(2) {
  -webkit-transform: translateX(0.13333333rem) scaleX(1.5);
  transform: translateX(0.13333333rem) scaleX(1.5);
  opacity: 0;

}

.changeFigure s:nth-child(3) {
  -webkit-transform: rotate(-45deg) translate(0) !important;
  transform: rotate(-45deg) translate(0) !important;
  opacity: 1;
}

/* banner三条横线——菜单 变成叉号 end */

/* PC导航 str*/


.menuCont {
  background-color: #fff;
  transition: height 0.5s;
  -webkit-transition: height 0.5s; /* Safari */
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  text-align: left;
  display: block;
  color: #000;
  overflow: auto;
  padding: 0 10% 0 10%;
  //  关于我们
  .us-row {
    height: 99%;
    padding-top: 1%;
    background-color: #fff;
    //pointer-events: none;
    z-index: 1000;
  }

  .us-col {
    height: 100%;
  }

  .us-col-d1 {
    height: 65%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    -webkit-transition: all .5s ease 0s;
    transition: all .5s ease 0s;

    .us-col-but {
      padding: 0.31rem;
    }
  }

  .us-col-d1:hover .us-col-but {
    background-color: #4ba477;
    padding: 0.38rem;
  }

  .us-col-d1:hover + .us-col-d2 {
    color: #4ba477;
  }

  .us-col-d2 {
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ily: 微软雅黑;
    color: #000;
  }
}

.li-p-sub:hover {
  .menuCont {
    display: inline;
    z-index: 100;
    height: 15em;
    visibility: visible;
  }
}

.menu_data {
  line-height: 1;
}


/* PC导航 end*/


/* 手机导航 str*/

@media (max-width: 767px) {
  .nav_mob {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 0;
    line-height: 50px;
    color: #333;
    background: #fff;
    transition: all 0.5s;
    text-align: left;
  }

  .nav_mob_item {
    background-color: #FFFFFF;
    padding: 0 0.5rem;
    border-top: 1px solid #f1f1f1;
    font-size: 16px;
    border-bottom: 1px solid #f1f1f1;
  }

  .nav_mob_item:hover {
    background-color: #4ba477;
  }
}



/* 手机导航 end*/


</style>