import Vue from 'vue';
import Vuex from 'vuex';
import loginMoudule from './moudules/loginMoudule'
Vue.use(Vuex);
const state = { }

const mutations = { }

const actions = { }

const modules = {
    loginMoudule
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules
})
