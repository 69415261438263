<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {

  }
}
</script>

<style>

#app {
  /*ily: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*ily: Microsoft YaHei, PingFang SC, Microsoft YaHei UI, Source Han Sans CN,SimSun, SimHei Arial, sans-serif;*/

}

body{
  /*background: #6a1208;*/
  margin: 0 ;
  font-family: AlibabaSansThai-Bd;

}

/* 在全局CSS里引用: */
/* 滚动条宽度 */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: rgba(163, 163, 164, 0.73);
  border-radius: 5px;
}
</style>
