<template>
  <div class="layout">
    <Header class="header"></Header>
    <router-view class="main"/>
    <Footer  class="footer"></Footer>
  </div>
</template>

<script>

import Header from "./Header.vue";
import Footer from "@/views/layout/footer.vue";
import axios from "axios";

export default {
  name: "index",
  components: {Footer, Header},
  data() {
    return {

    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll)
  },

  methods: {
    windowScroll(){
      // 获取当前滚动的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //计算当前透明度
      let opacity = scrollTop / 250 ;
      if (opacity >= 0.81) {
        opacity = 0.81;
      }
      let header = document.querySelector('.header'); // 获取头部
      // 写入样式
      header.style.background = 'rgba(0,0,0, ' + opacity + ')';
      console.log(opacity);
    },

  }
}
</script>

<style lang="less" scoped>
.layout {
  padding: 0;
  margin: 0;
  .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    min-height: 20px;
    background-color: rgba(0,0,0,0);
    z-index: 100;
  }
  @media (max-width: 1920px) {
    .header {
      height: 102px;
    }
  }
  @media(max-width: 1260px){
    .header {
      height: 61px;
    }
  }
  .main {
    top: 0;
    left: 0;
    overflow: auto;
  }

  .footer{
    width: 100%;
    overflow-x: hidden;
  }

}
</style>
