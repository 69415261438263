// 英文
const en = {
    language:'EN',
    home:'HOME',
    about_us:'ABOUT US',
    products_and_solutions:'PRODUCTS AND SOLUTIONS',
    solutions:"SOLUTIONS",
    product:'PRODUCT',

    news :'NEWS ',
    new:'NEWS',
    company_news:'Company News',
    industry_news:'Industry News',

    contact_us:'CONTACT US',

    company_profile:'Company Profile',
    development_path:'Development Path',
    job:'Job',

    search_page:'SEARCH PAGE',
    search_hint:'Enter search content',

    cultureAndValues:'Corporate Culture and Values',
    technologyAndInnovation:"Technology and Innovation",

    selDepHint:"Please select a department",
    selPostHint:"Please select a position",
    searchPositionHint:"search my jobs",
    reset:"reset",

    follow_us:"Follow US",
    hour24: "24-hour consultation hotline",

    home_img_tit:"Global layout",
    home_img_sub_tit:"Focusing on clean energy and jointly creating a low-carbon and green home",

    product_parms:'Technical Parameters',

    jobs:{
        department:"Department",
        post:"Post",
        area:"Area",
        academic_qualifications:"Academic Qualifications",
        obligation:"Obligation",
        require:"Require",
        salary: "Salary",
        delivery:"delivery",

        alert_tit:'Please send your resume to email',
        alert_but:'Copy',
        alert_but_message:'Copy success'
    },

    all:{
        news:'News',
        for_more:'For More',
        network_err_tit:'Request failed',
        network_err_msg:'Please check network connection',
        message_success:'message successfully',
        message_err:'Message failed',
    },

    contact_comp:{
        contact_information:'Contact Information',
        phone:'Phone',
        email:'E-Mail',
        website:'Website',
        address:'Address',
        message_board:'Message Board',
        name:'Name',
        message_content: 'Message Content',
        submit:'Submit',

        phone_from_warn:'please enter a valid phone numbe!',
    }

}

export default en;