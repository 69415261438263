import Vue from 'vue'
import VueRouter from 'vue-router'

//主框架页面
import Layout from  '../views/layout/index'
import Login from  '../views/login/login'
import Home from  '../views/home/index'

//异步加载页面

const AboutUs = ()=>import('../views/about_us/index.vue')
const ContactUs = ()=>import('../views/contact_us/index.vue')
const News = ()=>import('../views/news/index.vue')

const NewsInfo = ()=>import('../views/news/comp/news_info.vue')
const Product = ()=>import('../views/product/index.vue')

const proTypeList = ()=>import('../views/product/pro_type_list.vue')

const productInfo = ()=>import('../views/product/product_info.vue')

const Search = ()=>import('../views/search/index.vue')





Vue.use(VueRouter)

const routes = [
    {
        path:'',
        component:Layout, //主框架
        //路由元信息 用于路由拦截校验
        meta:{
          isLogin:true
        },
        children:[
            {
                path:'/',
                name:'Home', //首页
                component:Home
            },

            {
                path:'/about_us',
                name:'AboutUs', //关于我们
                component:AboutUs
            },

            {
                path:'/product',
                name:'Product', //产品页与服务
                component:Product
            },

            {
                path:'/pro_type_list',
                name:'proTypeList', //产品分类列表页
                component:proTypeList
            },

            {
                path:'/product_info',
                name:'productInfo', //产品详情
                component:productInfo
            },


            {
                path:'/contact_us',
                name:'ContactUs', //联系我们
                component:ContactUs
            },
            {
                path:'/news',
                name:'News', //新闻
                component:News
            },
            {
                path:'/news_info',
                name:'NewsInfo', //新闻详情
                component:NewsInfo
            },

            {
                path:'/search',
                name:'Search', //查询页
                component:Search
            }

        ]
    },
    {
        path:'/login',
        name:'Login',
        component:Login
    }
]

const router = new VueRouter({
    routes
})

//获取VUEx数据
import store from "@/store/store";


//路由拦截
// router.beforeEach((to,from,next)=>{
//     // console.log('to-----',to)
//
//     //判断登录
//     if (to.matched.some(ele => ele.meta.isLogin)) {
//
//         //判断当前用户是否登录
//         let user = store.state.loginMoudule.userObj
//
//         console.log('1111111111111',user)
//
//         if(user.token){
//             next()
//         }else{
//             next({
//                 path: '/login',
//                 // query: { redirect: to.fullPath }
//             })
//         }
//
//
//     } else { //不需要登录
//         next() // 确保一定要调用 next()
//     }
//
// })

export default router
