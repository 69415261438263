<template>

  <div class="main">

    <h1 style="color: #fff; height: 100px; font-size: 55px; font-weight: 700; z-index: 3;">光伏智慧云</h1>
    <div class="wrapper">
      <div class="title-text">

        <div class="title login" @click="login()">
          登录
        </div>
        <div class="title signup">
          注册
        </div>
      </div>
      <div class="form-container">
        <div class="slide-controls">
          <input type="radio" name="slide" id="login" checked>
          <input type="radio" name="slide" id="signup">
          <label for="login" class="slide login" @click="clickBut('login')">登录</label>
          <label for="signup" class="slide signup" @click="clickBut('signupBtn')">注册</label>
          <div class="slider-tab"></div>
        </div>
        <div class="form-inner">
          <form action="#" class="login">
            <div class="field">
              <input type="text" v-model="username" placeholder="用户名" required>
            </div>
            <div class="field">
              <input type="password" v-model="password" placeholder="密码" required>
            </div>
            <div class="pass-link">
              <a href="#">忘记密码?</a>
            </div>
            <div class="field btn">
              <div class="btn-layer"></div>
              <input @click="login()" type="submit" value="登录">
            </div>
            <div class="signup-link">
              没有账户? <a href="">立即注册</a>
            </div>
          </form>

          <form action="#" class="signup">
            <div class="field">
              <input type="text" placeholder="用户名" required>
            </div>
            <div class="field">
              <input type="password" placeholder="密码" required>
            </div>
            <div class="field">
              <input type="password" placeholder="邀请码" required>
            </div>
            <div class="field btn">
              <div class="btn-layer"></div>
              <input type="submit" value="注册">
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="footer">Copyright &copy; 信通科技 粤ICP备2021013735号-1</div>
  </div>


</template>


<script>
// import api from "@/api";

import {mapMutations} from 'vuex'

export default {
  name: "login",
  data() {
    return {
      username:'',
      password:'',
      loading:null,
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations('loginMoudule',['setUser']),
    clickBut(type) {
      console.log('type---------', type)
      const loginText = document.querySelector(".title-text .login");
      const loginForm = document.querySelector("form.login");
      if (type === 'signupBtn') {
        loginForm.style.marginLeft = "-50%";
        loginText.style.marginLeft = "-50%";
      } else {
        loginForm.style.marginLeft = "0%";
        loginText.style.marginLeft = "0%";
      }
    },

    //登录
    login(){
      if(this.username === ''){
        this.$message({
          message: '请输入用户名',
          type: 'warning'
        });
        return
      }
      if(this.password === ''){
        this.$message({
          message: '请输入密码',
          type: 'warning'
        });
        return
      }

      this.openFullScreen()

      let parms = {
        username:this.username,
        userpwd:this.password,
        action: 'login'
      }
      this.$api.xtApiPost(parms).then(res => {
        // console.log('res------',res.data)
        if(res.data.errorCode === 0){
          this.getLoginInfo(res.data.result)
          // this.$message({
          //   message: '登录成功',
          //   type: 'success'
          // });
        }else{
          this.$message.error('登录失败,用户名或密码错误！');
        }
      }).catch(e => {
        console.warn(e)
        this.$notify.error({
          title: '登录失败',
          message: '请检查您的网络连接'
        });
      })
    },


    openFullScreen() {
       this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
      setTimeout(() => {
        this.loading.close();
      }, 10000);
    },



    //查询登录用户信息
    getLoginInfo(token){
      let parms = {
        key:token,
        type:'query',
        action: 'user'
      }
      this.$api.xtApiPost(parms).then(res => {
        console.log('res------',res.data)
        this.loading.close();
        if(res.data.errorCode === 0){
          let userinfo = res.data.result
          let user = {
            token: token,
            userid: userinfo.userid,
            preuserid: userinfo.preuserid,
            username: userinfo.username,
            permissionid: userinfo.permissionid,
            timeoffset: userinfo.timeoffset,
            useraddr: userinfo.useraddr,
            email: userinfo.email,
            longitude: userinfo.longitude,
            latitude: userinfo.latitude,
            zoom: userinfo.zoom,
            userphone: userinfo.userphone,
            userremark: userinfo.userremark,
            unitmin: userinfo.unitmin,
            unitname: userinfo.unitname,
            imgurl: userinfo.imgurl,
            menu: userinfo.menu
          }

          this.setUser(user)

          localStorage.setItem('userLoginInfo',JSON.stringify(user))
          console.log(JSON.parse(localStorage.getItem('userLoginInfo')))
          this.$router.push('/')
          this.$notify({
            title: '成功',
            message: '登录成功',
            type: 'success'
          });


        }else{
          this.$notify.error({
            title: '登录失败',
            message: '用户信息获取失败'
          });
        }
      }).catch(e => {
        console.warn(e)
        this.$notify.error({
          title: '登录失败',
          message: '请检查您的网络连接'
        });
      })
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ily: 'Poppins', sans-serif;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: center;
  height: 28px;
  width: 100%;
  color: #ffffff;
  background: rgba(21, 69, 145, 0.4);

}

.main {
  width: 100%;
  height: 100vh;
  /*display: flex;*/
  justify-items: center;
  align-items: center;
  display: grid;
  place-items: center;
  /*background: -webkit-linear-gradient(left, #154591, #1d154e);*/
  background-image: url("../../assets/loginimg/2.jpg");
  background-size: cover;
}

.main::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

::selection {
  background: #154591;
  color: #fff;
}

.wrapper {
  margin-top: -700px;
  overflow: hidden;
  max-width: 390px;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.wrapper .title-text {
  display: flex;
  width: 200%;
}

.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

.slide-controls label.signup {
  color: #000;
}

.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #154591, #1d154e);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}

#signup:checked ~ label.login {
  color: #000;
}

#login:checked ~ label.signup {
  color: #000;
}

#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}

.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}

.form-inner form .field input:focus {
  border-color: #154591;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}

.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

form .field input:focus::placeholder {
  color: #b3b3b3;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #154591;
  text-decoration: none;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #154591, #1d154e, #154591, #1d154e);
  border-radius: 5px;
  transition: all 0.4s ease;;
}

form .btn:hover .btn-layer {
  left: 0;
}

form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

</style>
