<template>
  <div>
    <el-row :gutter="20" class="main">
      <el-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11">
        <div class="follow_us">{{ $t("follow_us") }}</div>
        <h5 class="hour24"> {{ $t("hour24") }}:</h5>
        <h1 style="color: #4ba477; font-weight: 600">{{ phone }}</h1>
        <div class="address">{{ $t("contact_comp.address") }} :
          {{ address }}
        </div>
      </el-col>
      <el-col :xs="24" :sm="13" :md="13" :lg="13" :xl="13">
        <el-row :gutter="24">
          <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4" class="menu_main">
            <div class="f_menu" @click="changeMenu('/about_us','')"> {{ $t("about_us") }}</div>
            <div class="menu_item" @click="changeMenu('/about_us',1)"> {{ $t("company_profile") }}</div>
            <div class="menu_item" @click="changeMenu('/about_us',2)">{{ $t("development_path") }}</div>
            <div class="menu_item" @click="changeMenu('/about_us',3)"> {{ $t("job") }}</div>
          </el-col>
          <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4" class="menu_main">
            <div class="f_menu">{{ $t("product") }}</div>
            <div class="menu_item" v-for="(item,index) in product_list" @click="changeMenu('/product',index+1)">
              {{ item.industryType.name }}
            </div>
          </el-col>

          <!--          <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4" class="menu_main">-->
          <!--            <div class="f_menu">{{ $t("solutions") }}</div>-->
          <!--          </el-col>-->


          <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4" class="menu_main">
            <div class="f_menu" @click="changeMenu('/news','')">{{ $t("news") }}</div>
            <div class="menu_item" @click="changeMenu('/news',1)">{{ $t("company_news") }}</div>
            <div class="menu_item" @click="changeMenu('/news',2)">{{ $t("industry_news") }}</div>
          </el-col>
          <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4" class="menu_main">
            <div class="f_menu" @click="changeMenu('/contact_us','')">{{ $t("contact_us") }}</div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="copy_main">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <!--        <div style="color:rgba(255,255,255,0.4); font-size: 14px; ">网站地图 <span>丨</span> 法律声明</div>-->
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="copyright">版权所有 深圳伟创数字能源科技有限公司 Copyright © 2022
          JSST All Rights Reserved
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "footer",

  data() {
    return {
      phone: '', //联系电话
      address: '', //公司地址
      product_list: [], //产品一级分类
    }
  },


  mounted() {
    let locale = localStorage.getItem('locale')
    console.log('当前系统语言------', locale)
  },

  created() {
    this.getBasicInfo()
    this.getProductList()
  },

  methods: {

    changeMenu(path, e) {
      this.$router.push({path: path, query: {id: e}})
    },

    //获取公司信息并存储到全局
    getBasicInfo() {
      // localStorage.removeItem("basic")
      this.$api.vcApiGet('basicInfo/basic', null).then(res => {
        // console.log('1111111111111111111111------', res.data.result)

        this.phone = res.data.result.supportHotline
        let locale = localStorage.getItem('locale')
        if (locale === 'en') {
          this.address = res.data.result.addressEn
        } else {
          this.address = res.data.result.addressCn
        }


        localStorage.setItem("basic", JSON.stringify(res.data.result))
      }).catch(e => {
        console.warn(e)
        this.$notify.error({
          title: this.$t("all.network_err_tit"),
          message: this.$t("all.network_err_msg")
        });
      })
    },


    //获取产品一级分类
    getProductList() {
      this.$api.vcApiGet('product/productList').then(res => {
        let locale = localStorage.getItem('locale')
        if (res.data.result.length > 0) {
          let list = res.data.result
          for (let i in list) {
            if (locale === 'en') {
              list[i].industryType.name = list[i].industryType.nameEn
            } else {
              list[i].industryType.name = list[i].industryType.nameCn
            }
          }

          this.product_list = list
        }
      })
    }
  }

}
</script>

<style scoped>

.main {
  padding: 0.6rem 2rem 0.4rem 2rem;
  background-color: #333
}

.menu_main {
  min-height: 150px
}

.f_menu {
  cursor: pointer;
  font-size: 0.19rem;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.menu_item {
  cursor: pointer;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  margin: 15px 0;
}


.follow_us {
  color: #FFFFFF;
  font-size: 0.19rem;
}

.hour24 {
  color: rgba(255, 255, 255, 0.4);
  margin-top: 20px
}

.address {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.copy_main {
  padding: 0.1rem 2rem;
  background-color: #333;
  border-top: 1px solid #6b6b6b
}

.copyright {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px
}


@media (max-width: 767px) {
  .main {
    padding: 0 1rem 0 1rem;
    background-color: #333
  }

  .menu_main {
    min-height: 125px
  }

  .f_menu {
    cursor: pointer;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .menu_item {
    cursor: pointer;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    margin: 8px 0;
  }

  .follow_us {
    font-size: 14px;
    margin-top: 20px;
  }

  .hour24 {
    margin-top: 8px
  }

  .address {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .copy_main {
    padding: 0.1rem 1rem;
    background-color: #333;
    border-top: 1px solid #6b6b6b
  }

  .copyright {
    padding: 8px 0;
    font-size: 10px
  }
}

</style>