export default {
    namespaced:true,
    state:{
        userObj:{
            token:'',
            userid: 0,
            preuserid: 0,
            username: '',
            permissionid: 0,
            timeoffset: '',
            useraddr: '',
            email: '',
            longitude: '',
            latitude: '',
            zoom: 0,
            userphone: '',
            userremark: '',
            unitmin: '',
            unitname: '',
            imgurl: '',
            menu: null
        }

    },
    mutations:{
        //设置用户
        setUser(state,userObj){
            state.userObj = userObj
        },

        clearUser(state){ //清空用户
            state.userObj = {
                token:'',
                userid: 0,
                preuserid: 0,
                username: '',
                permissionid: 0,
                timeoffset: '',
                useraddr: '',
                email: '',
                longitude: '',
                latitude: '',
                zoom: 0,
                userphone: '',
                userremark: '',
                unitmin: '',
                unitname: '',
                imgurl: '',
                menu: null
            }
        }
    },
    actions:{

    },
    getters:{

    }
}
