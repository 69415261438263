
const base = {


    // VCStaticResourceURL:'http://192.168.1.89:8080/jeecg-boot/sys/common/static/',
    // vc: 'http://192.168.1.89:8080/jeecg-boot/', //统一请求URL

    VCStaticResourceURL:'http://39.108.163.116:8080/jeecg-boot/sys/common/static/',
    vc: 'http://39.108.163.116:8080/jeecg-boot/', //统一请求URL


}
export default base
