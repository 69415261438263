// 请求方法

import axios from "axios";
import base from './base'
import qs from "qs"

// const qs = require('querystring')
const api = {
    //信通 不带body的post
    xtApiPost(parm){
        return axios.post(base.vc,null,{params:parm} )
    },
    //带body的post
    xtApiPostBody(data,parm){
        return axios.post(base.vc,data,{params:parm} )
    },

    //get
    vcApiGet(url,data){
        return axios.get(base.vc+url,null)
    },

    //Post
    vcApiPost(url,data){
        return axios.post(base.vc+url,qs.stringify(data))
    },


}

export default api
