import Vue from 'vue'
import App from './App.vue'
// 引入
import '@/assets/fonts/fonts.css'

import router from './router'
import store from './store/store'
import ElementUI from 'element-ui';
// import './plugins/element.js'
import api from './api/index';
import axios from "axios";

import util from "./util/util";
import protRecordAddress from "./util/protRecordAddress";



import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts;
Vue.prototype.$axios=axios;
Vue.prototype.$util=util;
Vue.prototype.$protRecordAddress=protRecordAddress;

//全局挂载
Vue.prototype.$api = api

import jquery from 'jquery'
Vue.prototype.$ = jquery

import i18n from "./i18n"
// import animated from 'animate.css' // npm install animate.css --save安装，在引入

let loginUser = localStorage.getItem('userLoginInfo')
if(loginUser){
  store.commit('loginMoudule/setUser',JSON.parse(loginUser))
}


import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'GpgFlfOdLwUp72ltRCvVQAv8aKkbK7gm'
})

router.afterEach((to,from,next) => {
  window.scrollTo(0,0); //切换路由之后滚动条始终在最顶部
});


Vue.config.productionTip = false
// Vue.use(animated);
Vue.use(ElementUI);
new Vue({
  router,
  store,  //使用store
  i18n,
  render: h => h(App),
}).$mount('#app')



