// 中文
const zh = {
    language:'CN',
    home:'首页',
    about_us:'关于我们',
    products_and_solutions:'产品与方案',
    solutions:"解决方案",
    product:'产品',

    news :'新闻中心',
    new:'新闻',
    company_news:'公司新闻',
    industry_news:'行业动态',


    contact_us:'联系我们',

    company_profile:'公司简介',
    development_path:'发展历程',
    job:'人才招聘',

    search_page:'搜索页',
    search_hint:'请输入搜素内容',

    cultureAndValues:'企业文化与价值观',
    technologyAndInnovation:"技术与创新",

    selDepHint:"请选择部门",
    selPostHint:"请选择岗位",
    searchPositionHint:"搜索我的职位",
    reset:"重置",


    follow_us:"关注我们",
    hour24: "24小时咨询热线",

    home_img_tit:"布局全球",
    home_img_sub_tit:"专注清洁能源，共同创造低碳绿色家园",

    product_parms:'技术参数',

    jobs:{
        department:"部门",
        post:"岗位",
        area:"地区",
        academic_qualifications:"学历",
        obligation:"岗位职责",
        require:"岗位要求",
        salary: "薪资",
        delivery:"投递简历",

        alert_tit:'请将您的简历发送至邮箱',
        alert_but:'复制',
        alert_but_message:'复制成功'

    },

    all:{
        news:'新闻资讯',
        for_more:'了解更多',
        network_err_tit:'请求失败',
        network_err_msg:'请检查您的网络连接',
        message_success:'留言成功！',
        message_err:'留言失败！',
    },

    contact_comp:{
        contact_information:'联系信息',
        phone:'电话',
        email:'邮箱',
        website:'网址',
        address:'地址',
        message_board:'留言板',
        name:'姓名',
        message_content: '留言内容',
        submit:'提交',

        phone_from_warn:'请输入正确的手机号！',
        // phone_from_warn:'',
        // phone_from_warn:'',
    }
}

export default zh;