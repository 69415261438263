<template>
  <div style="background-color: #fff">

    <!--PC banner区域 Sta -->
    <el-carousel class="banner" height="98.7vh">
      <el-carousel-item class="banner_item1" v-for="item in bannerList" :name="item.title "
                        :style="{backgroundImage: 'url(' + Base.VCStaticResourceURL + item.bannerImg + ')' }">
        <div class="banner_main">
          <h1 class="animate__font-fam animate__fadeInLeft banner_title">{{ item.title }}</h1>
          <h1 class="animate__font-fam animate__fadeInRight banner_titleb">{{ item.subtitle }}</h1>
          <!--          <img :src="'http://192.168.1.89:8080/jeecg-boot/sys/common/static/'+item.bannerImg">-->


          <button class="light" @click="changeMenu(item.butUrl,item.butUrl.substr(item.butUrl.length-1,1))">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {{ item.butTitle }}
          </button>
        </div>
      </el-carousel-item>

    </el-carousel>
    <!--PC banner区域 End  -->

    <!--移动端banner区域Sta -->
    <el-carousel class="mob_banner" height="260px">
      <el-carousel-item class="banner_item1" v-for="item in bannerList" :name="item.title "
                        :style="{backgroundImage: 'url(' + Base.VCStaticResourceURL + item.bannerImg + ')' }">
        <div class="banner_main">
          <h1 class="animate__font-fam animate__fadeInLeft banner_title">{{ item.title }}</h1>
          <h1 class="animate__font-fam animate__fadeInRight banner_titleb">
            {{ item.subtitle }}</h1>
          <button class="light" @click="changeMenu(item.butUrl,null)">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {{ item.butTitle }}
          </button>

        </div>
      </el-carousel-item>

<!--      <el-carousel-item class="banner_item2">-->
<!--        <div class="banner_main">-->
<!--          <h1 class="animate__font-fam animate__fadeInLeft banner_title">锐意创新 能源无界</h1>-->
<!--          <h1 class="animate__font-fam animate__fadeInRight banner_titleb">-->
<!--            面向全球、全方位的充电及储能电能变换产品和解决方案</h1>-->
<!--          <div class="light">-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            <div></div>-->
<!--            充储方案-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-carousel-item>-->
    </el-carousel>
    <!--移动端banner区域 End  -->

    <!--产品与方案 Sta-->
    <div>
      <div class="goods_title">
<!--        <h1>产品与方案</h1>-->
        <h1>{{ $t("products_and_solutions") }}</h1>
      </div>
      <el-row :gutter="0" style=" margin-left: 0.5rem; margin-right: 0.5rem">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="goods_item" v-for="item in productsAndSolutionsList">
          <div style=" margin:0 0.15rem 0.15rem 0.15rem " @click="changeMenu(item.url,item.url.substr(item.url.length-1,1))">
            <img :src="Base.VCStaticResourceURL+ item.img" style="height: 100%; width: 100%;   background-repeat: no-repeat; background-size: cover; -webkit-background-size: cover; -o-background-size: cover; background-position: 0 center;">
            <h3 class="animate__font-fam animate__fadeInLeft goods_item_tit">{{item.title}}</h3>
            <h4 class="animate__font-fam animate__fadeInRight goods_item_tit2">
              {{item.subtitle}}</h4>
          </div>
        </el-col>
      </el-row>
      <div class="goods_test">
      </div>
    </div>
    <div style="position: relative">
      <div
          style="height: 100%; width: 100%;position: absolute; top: 10px; display: flex;justify-content: center;  z-index: 100">
        <el-row style="margin-top: 1rem">
          <el-col :span="24">
            <div class="ad_tit_a">{{ $t("home_img_tit") }}</div>
          </el-col>
          <el-col :span="24">
            <div class="ad_tit_b">{{ $t("home_img_sub_tit") }}</div>
          </el-col>
        </el-row>
      </div>
      <img src="../../assets/test/c1.jpg" style="height: 100%; width: 100%;"/>

    </div>
    <!--产品与方案 End-->

    <!--新闻咨询 Sta-->
    <div>
      <div class="goods_title">
<!--        <h1>新闻资讯</h1>-->
        <h1>{{ $t("all.news") }}</h1>
      </div>
      <el-row :gutter="20" style="margin: 0 0.3rem 0.9rem 0.3rem">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="(item,index) in newsAdvisoryList">
          <div class="news1" :style="{backgroundImage: 'url(' + Base.VCStaticResourceURL + item.img + ')' }" >
            <el-row>
              <el-col :span="24">
                <div style="color: #fff; font-size: 22px; text-align: center">{{item.title}}</div>
              </el-col>
              <el-col :span="24">
                <div style="color: #fff;  font-size: 16px;text-align: center">{{item.subtitle}}</div>
              </el-col>
              <el-col :span="24" style="text-align: center; margin-top: 16px">
                <button class="news_but" @click="changeMenu('news',index+1)">{{ $t("all.for_more") }} </button>
              </el-col>
            </el-row>
          </div>
        </el-col>

      </el-row>
    </div>
    <!--新闻咨询 Ens-->


  </div>
</template>

<script>
import Util from "@/util/util";
import Base from "@/api/base";

export default {
  name: "index",
  computed: {
    Base() {
      return Base
    },
    Util() {
      return Util
    }
  },
  data() {
    return {
      bannerList: [],
      newsAdvisoryList: [],
      productsAndSolutionsList: [],
    }
  },

  created() {

  },
  mounted() {
    this.getHomeInfo()
  },
  methods: {
    //获取Home页面数据
    getHomeInfo() {
      this.$api.vcApiGet('home/homeInfo', null).then(res => {
        //1 console.log('1111111111111111111111------', res.data.result)
        let locale = localStorage.getItem('locale')
        if (res.data.result.bannerList.length > 0) {
          for (let i in res.data.result.bannerList) {
            if (locale === 'en') {
              res.data.result.bannerList[i].butTitle = res.data.result.bannerList[i].butTitleEn
              res.data.result.bannerList[i].subtitle = res.data.result.bannerList[i].subtitleEn
              res.data.result.bannerList[i].title = res.data.result.bannerList[i].titleEn
            } else {
              res.data.result.bannerList[i].butTitle = res.data.result.bannerList[i].butTitleCn
              res.data.result.bannerList[i].subtitle = res.data.result.bannerList[i].subtitleCn
              res.data.result.bannerList[i].title = res.data.result.bannerList[i].titleCn
            }
          }
        }
        this.bannerList = res.data.result.bannerList

        // 2
        if (res.data.result.productsAndSolutionsList.length > 0) {
          for (let i in res.data.result.productsAndSolutionsList) {
            if (locale === 'en') {
              res.data.result.productsAndSolutionsList[i].title = res.data.result.productsAndSolutionsList[i].titleEn
              res.data.result.productsAndSolutionsList[i].subtitle = res.data.result.productsAndSolutionsList[i].subtitleEn
            }
          }
        }
        this.productsAndSolutionsList = res.data.result.productsAndSolutionsList

        // 3
        if (res.data.result.newsAdvisoryList.length > 0) {
          for (let i in res.data.result.newsAdvisoryList) {
            if (locale === 'en') {
              res.data.result.newsAdvisoryList[i].title = res.data.result.newsAdvisoryList[i].titleEn
              res.data.result.newsAdvisoryList[i].subtitle = res.data.result.newsAdvisoryList[i].subtitleEn
            }else{
              res.data.result.newsAdvisoryList[i].title = res.data.result.newsAdvisoryList[i].titleCn
              res.data.result.newsAdvisoryList[i].subtitle = res.data.result.newsAdvisoryList[i].subtitleCn
            }
          }
        }

        this.newsAdvisoryList = res.data.result.newsAdvisoryList
        console.log('2222222222222------', res.data.result)



      }).catch(e => {
        console.warn(e)
        this.$notify.error({
          title: this.$t("all.network_err_tit"),
          message: this.$t("all.network_err_msg")
        });
      })
    },

    // 路由跳转
    changeMenu(path, e) {
      this.$router.push({path: path, query: {id: e}})
    },

  }
}
</script>


<style lang="less" scoped>

* {
  ily: 'Microsoft YaHei', 'PingFang SC', 'Microsoft YaHei UI', 'Source Han Sans CN', 'Source Han Serif CN', 'SimSun', 'SimHei', 'Arial';
  font-size: 16px;
}

//新闻咨询------------STA-----
.news1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
  //background-image: url("@/assets/test/c2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: 0 center;
  filter: contrast(96%);
}


@media (max-width: 767px) {
  .news1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    width: 100%;
    //background-image: url("@/assets/test/c2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: 0 center;
    filter: contrast(96%);
    margin-bottom: 20px;
  }
}




.news_but {
  height: 22px;
  font-size: 12px;
  color: #fff;
  background-color: rgba(94, 165, 253, 0);
  border: 1px solid #fff;
  padding: 0 60px
}

.news_but:before {
  height: 22px;
  font-size: 12px;
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0 60px
}

.news_but:active {
  height: 22px;
  font-size: 12px;
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0 60px
}

//新闻咨询------------END-----


//产品与方案----------STA-----
.goods_title {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 0.38rem;
    color: #000;
  }
}

@media (max-width: 767px) {
  .goods_title {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: #000;
      font-size: 18px;
    }
  }

}

.goods_item {
  background-color: #fff;
  transition: all 0.3s linear;
}

.goods_item:hover {
  transition: all .3s linear;
  transform: scaleY(1.03);

  .goods_item_tit {
    color: #4ba477;
    font-size: 24px;
  }

  .goods_item_tit2 {
    color: #000;
  }
}

.goods_item_tit {
  margin-top: 8px;
  color: #000;
  font-size: 24px;
}

.goods_item_tit2 {
  margin-bottom: 30px;
}

.ad_tit_a {
  color: #fff;
  font-size: 0.25rem;
  text-align: center
}

.ad_tit_b {
  color: #fff;
  font-size: 0.4rem;
  text-align: center
}

@media (max-width: 767px) {

  .goods_item:hover {
    transition: all .3s linear;
    transform: scaleY(1.03);

    .goods_item_tit {
      color: #36942d;
      font-size: 19px;
    }

    .goods_item_tit2 {
      color: #000;
    }
  }

  .goods_item_tit {
    margin-top: 8px;
    color: #000;
    font-size: 19px;
  }

  .ad_tit_a {
    color: #fff;
    font-size: 16px;
    text-align: center
  }

  .ad_tit_b {
    color: #fff;
    font-size: 22px;
    text-align: center
  }
}

//产品与方案----------END-----

// banner区域-----sta
.banner_main {
  height: 2rem;
  width: 100%;
  margin-left: 2rem;
  margin-top: -2rem
}

.banner_title {
  color: #fff;
  font-size: 0.59rem;
  font-weight: 700
}

.banner_titleb {
  color: #fff;
  font-size: 0.28rem;
}

.banner_item1 {
  //display: flex;
  //align-items: center;
  //background-image: url("@/assets/banner/banner01.jpg");
  //filter: contrast(96%);
  //width: 100%;
  //background-size: 100% auto;
  //background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  //background-image: url("@/assets/banner/banner01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: 0 center;
  filter: contrast(96%);
}


.light {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  margin-top: 0.3rem;
  max-width: 5.2rem;
  text-align: center;
  position: relative;
  padding: 0.12rem 0.2rem;
  color: #fff;
  font-size: 0.23rem;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  cursor: pointer;
  overflow: hidden;
}

@media (max-width: 2560px) {
  .mob_banner {
    display: none;
  }
}


@media (max-width: 767px) {
  .banner {
    display: none;
  }

  .mob_banner {
    display: block;
  }

  .banner_main {
    height: 100px;
    width: 100%;
    margin: 20px;

  }

  .banner_title {
    margin: 0;
    font-size: 22px;
    font-weight: 700
  }

  .banner_titleb {
    font-size: 14px;
  }

  .banner_item1 {

    //display: flex;
    //align-items: center;
    //background-image: url("@/assets/banner/banner01.jpg");
    //filter: contrast(96%);
    //width: 100%;
    //background-size: 100% auto;
    //background-repeat:no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("@/assets/banner/banner01.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: 0 center;
    filter: contrast(96%);
  }

  .banner_item2 {
    height: 100%;
    width: 100%;
    background-image: url("@/assets/banner/banner03.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: 0 center;
    filter: contrast(96%);
  }

  .light {
    margin-top: 10px;
    max-width: 440px;
    text-align: center;
    position: relative;
    padding: 6px 10px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    cursor: pointer;
    overflow: hidden;
  }
}

.light:hover {
  border-radius: 4px;
  background-color: #fff;
  color: #4ba477;
  box-shadow: 0 0 5px #fff,
  0 0 25px #fff,
  0 0 50px #fff,
  0 0 200px #fff;
}

.light div {
  position: absolute;
}

.light div:nth-child(1) {
  width: 100%;
  height: 2px;
  top: 0;
  left: -100%;
  background: linear-gradient(to right, transparent, #fff);
  animation: animate1 2s linear infinite;
}

.light div:nth-child(2) {
  width: 2px;
  height: 100%;
  top: -100%;
  right: 0;
  background: linear-gradient(to bottom, transparent, #fff);
  animation: animate2 2s linear infinite;
  animation-delay: 0.5s;
}

.light div:nth-child(3) {
  width: 100%;
  height: 2px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(to left, transparent, #fff);
  animation: animate3 2s linear infinite;
  animation-delay: 1s;
}

.light div:nth-child(4) {
  width: 2px;
  height: 100%;
  bottom: -100%;
  left: 0;
  background: linear-gradient(to top, transparent, #fff);
  animation: animate4 2s linear infinite;
  animation-delay: 1.5s;
}

@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}

@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%, 100% {
    top: 100%;
  }
}

@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%, 100% {
    right: 100%;
  }
}

@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%, 100% {
    bottom: 100%;
  }
}

// banner区域-----END---------------
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
